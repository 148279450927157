<template>
    <div class="rules-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="rules-list row g-2 mb-4">
                <div class="col-md-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search rules"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <div class="col-md-auto">
                    <date-range-picker
                        ref="picker"
                        v-model="range"
                        opens="right"
                        time-picker
                        time-picker-24-hours
                        show-dropdowns
                        control-container-class="rules-list__date-range-picker"
                        :linked-calendars="false"
                        @update="changeDate"
                    >
                        <template #input>
                            <em class="fa fa-fw fa-calendar ml-n1"></em>
                            {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                            {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                            <strong class="caret"></strong>
                        </template>
                    </date-range-picker>
                </div>
            </div>
            <ui-table class="mb-4" :items="rules" :fields="fields" :busy="busy">
                <template #cell(name)="data">
                    <template v-if="data.item.name">
                        <strong>{{ data.item.name }}</strong>
                        <div class="text-muted">
                            {{ data.item.description }}
                        </div>
                    </template>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(score)="data">
                    <template v-if="data.item.score">
                       {{ data.item.score }}
                    </template>
                    <template v-else-if="data.item.amount">
                        {{ data.item.amount }} {{ data.item.currency }}
                    </template>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(active)="data">
                    <ui-status class="fs-13px" :status="data.item.active ? 'active' : 'inactive'" />
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>   
                <template #cell(updated_at)="data">
                    {{ $dayjs(data.item.updated_at).format('LL') }} <br />
                    {{ $dayjs(data.item.updated_at).format('LTS') }}
                </template> 
                <template #cellFull="data">
                    <tr v-if="data.item.error">
                        <td class="col-full" :colspan="fields.length">
                            <p class="text-center text-red p-3 mb-0">
                                <span class="fas fa-info-circle"></span>
                                {{ data.item.error }}
                            </p>
                        </td>
                    </tr>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';

import UiInput from '@/components/ui/Input';
import UiPagination from '@/components/ui/Pagination';
import UiTable from '@/components/ui/Table';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton';
import UiStatus from '@/components/ui/BadgeStatus';

import table_mixin from '@/mixins/tables.mixin';

export default {
    components: {
        DateRangePicker,
        UiInput,
        UiPagination,
        UiTable,
        UiTableSkeleton,
        UiStatus,
    },
    mixins: [table_mixin],
    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };

        return {
            busy: false,
            rules: [],
            creating: false,
            fields: [
                { key: 'name', label: 'Name' },
                { key: 'score', label: 'Score'},
                { key: 'active', label: 'Active' },
                { key: 'created_at', label: 'Created at' },
                { key: 'updated_at', label: 'Updated at' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                active: undefined,
                range: { ...range },
            },
            range: { ...range },
            inititialLoading: true,
            loading: false,
            total_pages: null,
        };
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getRules);
            },
        }
    },
    methods: {
        changeDate(data) {
            this.filters.range = data;
        },
        async getRules() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    'created_at[from]': this.$dayjs(this.filters.range.startDate).toISOString(),
                    'created_at[to]': this.$dayjs(this.filters.range.endDate).toISOString(),
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/rules', { params });

                this.total_pages = data.pages;
                this.rules= data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
    },
};
</script>

<style lang="scss">
.rules-list {
    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>
